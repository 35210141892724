<template>
    <div class="">
        <income-expense-create type="income"/>
        <form @submit.prevent="getList()">
            <div class="mb-3 row g-2">
                <div class="col-sm-4">
                    <date-range-picker class="w-100" title="Date Range" v_model="date_range"/>
                </div>
                <div class="col-sm-5">
                    <drop-down title="Doctor" v_model="doctor"/>
                </div>
                <div class="col-sm-3">
                    <input type="submit" class="btn btn-primary w-100" value="Submit">
                </div>
            </div>
        </form>
        <div class="row">
            <div class="table-responsive">
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">SL</th>
                        <th scope="col">Date</th>
                        <th scope="col">Doctor</th>
                        <th scope="col">Patient</th>
                        <th scope="col">Details</th>
                        <th scope="col">Amount Due</th>
                        <th scope="col">Amount Paid</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="lists.length">
                        <tr v-for="(list, index) in lists" class="align-middle">
                            <th scope="row">{{ table.pagination.from + index }}</th>
                            <td>{{ list.transaction_date | dateFormat }}</td>
                            <td>{{ list.doctor ? list.doctor.name : '' }}</td>
                            <td>{{ list.transactionable ? list.transactionable.name : '' }}</td>
                            <td v-html="list.details"></td>
                            <td>{{ list.total_due | numberFormat }}</td>
                            <td>{{ list.total_paid | numberFormat }}</td>
                        </tr>
                    </template>
                    <template v-if="!lists.length">
                        <tr>
                            <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                        </tr>
                    </template>
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colspan="5">Total</th>
                        <th>{{ getTotalDue |numberFormat }}</th>
                        <th>{{ getTotalPaid |numberFormat }}</th>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
        </div>
    </div>
</template>

<script>
import DateRangePicker     from "@/components/picker/DateRangePicker";
import moment              from "moment";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";
import IncomeExpenseCreate from "./IncomeExpenseCreate";

export default {
    name      : "Income",
    components: {IncomeExpenseCreate, DateRangePicker, Pagination},
    data      : () => ({
        form  : {
            date_range: [
                moment().startOf('month').format("YYYY-MM-DD"),
                moment(new Date()).format("YYYY-MM-DD"),
            ],
            doctor    : '',
        },
        errors: [],
        lists : [],
        table : {
            per_page  : 10,
            date_range: '',
            staff     : '',
            pagination: {
                current_page: 1
            }
        }
    }),
    computed  : {
        getTotalPaid() {
            let total_amount = 0;
            this.lists.forEach((item, i) => {
                total_amount += parseFloat(item.total_paid);
            });
            return total_amount;
        },
        getTotalDue() {
            let total_amount = 0;
            this.lists.forEach((item, i) => {
                total_amount += parseFloat(item.total_due);
            });
            return total_amount;
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let params = {
                ...this.table,
                page      : this.table.pagination.current_page,
                date_range: this.form.date_range,
                doctor_id : this.form.doctor ? this.form.doctor.id : '',
            };
            ApiService.get('/accounts/income', {params: params}).then(({data}) => {
                this.lists            = data.data;
                this.table.pagination = data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
    }
}
</script>
